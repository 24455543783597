import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "./Button";
import { ReactComponent as Logo } from "../../svg/logo.svg";

interface IProps {
  className?: string;
}

export default function Header({ className }: IProps) {
  const [active, setActive] = useState(false);

  return (
    <header className={classNames("header", { navActive: active }, className)}>
      <div className="width">
        <div className="header-flex">
          <Link className="header-logo" to="/">
            <Logo />
          </Link>

          <button onClick={() => setActive(!active)} className="hamburger">
            <span className="hamburger-bar" />
            <span className="hamburger-bar" />
            <span className="hamburger-bar" />
            <span className="hamburger-bar" />
          </button>

          <ul className="header-nav">
            <li className="header-nav-item">
              <Link to="/" className="header-nav-anchor">
                Home
              </Link>
            </li>
            {/* <li className="header-nav-item">
              <Link href="/">
                <a className="header-nav-anchor">The Agora</a>
              </Link>
            </li> */}
            <li className="header-nav-item">
              <a
                className="header-nav-anchor"
                href="https://twitter.com/kongiscash"
              >
                Twitter
              </a>
            </li>
            <li className="header-nav-item">
              <a
                href="https://discord.gg/dypeg4JfTX"
                className="header-nav-anchor"
              >
                Discord
              </a>
            </li>

            <li className="header-nav-item header-button">
              <Button href="https://app.uniswap.org/#/swap?exactField=output&exactAmount=1&outputCurrency=0x77F0cc420dEa0aE726Db6Bef1460A4B69176A8Ea&chain=mainnet" color="line-white">
                Acquire Citzenship
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
