import { useEffect } from "react";
import classNames from "classnames";
import { ReactComponent as Arrow } from "../../svg/arrow.svg";
import { ReactComponent as DiagonalArrow } from "../../svg/diagonal-arrow.svg";
import { ReactComponent as Globe } from "../../svg/globe.svg";
import { ReactComponent as Twitter } from "../../svg/twitter.svg";
import { ReactComponent as Discord } from "../../svg/discord.svg";

import Button from "./Button";

interface IProps {
  className?: string;
}

export default function Footer({ className }: IProps) {
  useEffect(() => {
    // ts-ignore
    const win: any = window;
    win["KlaviyoSubscribe"]?.attachToForms?.("#signup", {
      hide_form_on_success: true,
    });
  }, []);

  return (
    <footer className={classNames("footer", className)}>
      <div className="width">
        <div className="footer-flex">
          <div className="footer-left">
            <h2>
              Join the
              <br /> community
            </h2>

            <ul className="footer-social">
              <li>
                <a
                  href="https://twitter.com/kongiscash"
                  className="footer-social-anchor"
                >
                  <span className="footer-social-anchor-left">
                    <Twitter />
                  </span>
                  <span className="footer-social-anchor-right">
                    <strong>
                      Twitter <DiagonalArrow />
                    </strong>
                    Follow @kongiscash on Twitter
                  </span>
                </a>
              </li>

              <li>
                <a
                  href="https://discord.gg/dypeg4JfTX"
                  className="footer-social-anchor"
                >
                  <span className="footer-social-anchor-left">
                    <Discord />
                  </span>
                  <span className="footer-social-anchor-right">
                    <strong>
                      Discord <DiagonalArrow />
                    </strong>
                    Join our Discord community
                  </span>
                </a>
              </li>

              <li>
                <a
                  href="https://medium.com/kong-land-embassy"
                  className="footer-social-anchor"
                >
                  <span className="footer-social-anchor-left">
                    <span className="footer-globe">
                      <Globe />
                    </span>
                  </span>
                  <span className="footer-social-anchor-right">
                    <strong>
                      Blog <DiagonalArrow />
                    </strong>
                    Read our articles
                  </span>
                </a>
              </li>
            </ul>
          </div>

          <div className="footer-right">
            <div className="footer-right-top">
              <h2>
                Get the <br />
                next drop
              </h2>
              <Globe />
            </div>

            <form id="signup" className="footer-right-mail">
              <label htmlFor="email">Sign up for our newsletter</label>
              <input type="hidden" name="g" value="TXfEdD" />

              <div className="footer-right-mail-fields">
                <input
                  name="email"
                  id="email"
                  placeholder="citizen@kong.land"
                />

                <button type="submit">
                  <Arrow />
                </button>
              </div>

              <div className="klaviyo_messages">
                <div
                  className="success_message"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="error_message"
                  style={{ display: "none" }}
                ></div>
              </div>
            </form>

            <div className="footer-right-bottom">
              <ul>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/kong-cash/id1479791375"
                    className="footer-social-anchor"
                  >
                    iOS App
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=ch.vrfy"
                    className="footer-social-anchor"
                  >
                    Android App
                  </a>
                </li>
              </ul>

              <p>© 2023 KONG LAND</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
